import React, { useEffect, useState } from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageSection from '../components/PageSection'
import ContractForm from '../components/ContractForm'
import { texts } from '../components/BewerbungFormDefinition'

import { firestore } from '../firebase'
import appConfig from '../appConfig'

const CcPreview = () => (
  <div
    className="w-100 d-flex pt-5 flex-column justify-content-center align-items-center text-center"
    style={{ marginBottom: '7rem' }}
  >
    <p>Bestätigung der AGBs wird möglich nach Beendigung der Bewerbungsphase</p>
    <br />
    <p>GCT consent will be possibly after end of application period</p>
  </div>
)

function stringToDate(value) {
  var isEuro = value.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/)
  var isIso = value.match(/^\d{4}-\d{1,2}-\d{1,2}$/)

  if (isEuro) {
    value = value.split('.')
    value = [value[2], value[1], value[0]].join('-') //.reverse() isn't working in IEdge
    isIso = true
  }

  if (isEuro || isIso) {
    return new Date(value)
  } else {
    return false
  }

  // if (isNaN(date.getTime()) || !isIso) return false
  // return date
}

const fetchArtistData = async (searchId) => {
  try {
    const artistQuery = await firestore
      .collection('artists')
      .where('artistId', '==', searchId)
      .get()

    if (!artistQuery.empty) {
      const resultId = artistQuery.docs[0].id
      const resultData = artistQuery.docs[0].data()

      const initialBirthday = resultData.birthday?.seconds
        ? new Date(resultData.birthday.toDate())
        : stringToDate(resultData.birthday) || stringToDate('01.01.2021')

      const newInitialData = {
        id: resultId,
        birthday: initialBirthday,
        last_name: resultData.last_name,
        first_name: resultData.first_name,
        email: resultData.email,
        tax_number: resultData.tax_number || '',
        tax_office: resultData.tax_office || '',
        liability: resultData.liability,
        // consentInsurance: resultData.consentInsurance,
        // consentContract: resultData.consentContract,
        consentGTC_20231: resultData.consentGTC_20231 || false,
        join20231: resultData.join20231 || false,
        account_holder: resultData.account_holder || '',
        iban: resultData.iban || '',
        bic: resultData.bic || '',
        bank_name: resultData.bank_name || '',
      }
      console.log('newInitialData for artistId ' + searchId, newInitialData)
      return newInitialData
    } else {
      console.log('no data found for artistId: ' + searchId)
      return null
    }
  } catch (err) {
    console.error(err.message)
  }
}

// eslint-disable-next-line react/prop-types
function ConfirmContract({ location }) {
  const [formLanguage, setFormLanguage] = useState('de')
  const [searchId, setSearchId] = useState('')
  const [searchFormError, setSearchFormError] = useState(null)
  const [initialData, setInitialData] = useState(null)

  const onSelectChange = (evt) => {
    setFormLanguage(evt.target.value)
  }

  useEffect(() => {
    const loadInit = async () => {
      const paramsString = location?.search?.split('?')[1]
      const searchParams = new URLSearchParams(paramsString)
      const paramArtistId = searchParams.get('artistId')

      if (paramArtistId?.length > 0) {
        const artistData = await fetchArtistData(paramArtistId)
        if (artistData.first_name.length > 0) {
          setInitialData(artistData)
          setSearchId(paramArtistId)
        }
      }
    }
    loadInit().catch(console.error)
  }, [fetchArtistData])

  const handleSearchSubmit = async (event) => {
    event.preventDefault()
    try {
      console.log(searchId)
      const artistData = await fetchArtistData(searchId)
      console.log(artistData)

      if (artistData.first_name.length > 0 && artistData.join20231) {
        setSearchFormError(null)
        setInitialData(artistData)
      } else {
        setSearchFormError(texts[formLanguage].radioChoiceError)
      }
    } catch (err) {
      console.error(err.message)
      setSearchFormError(texts[formLanguage].radioChoiceError)
    }
  }

  return (
    <Layout location={location} showSlider={false} showFooter={false}>
      <SEO
        keywords={[`kuboshow`, `vertragsbestaetigung`]}
        title="Vertragsbestätigung"
      />
      {appConfig.showConfirmContract !== true ? (
        <PageSection>
          <CcPreview />
        </PageSection>
      ) : (
        <PageSection>
          <div className="row mb-2">
            <div className="col-12 col-md-8">
              <div className="d-flex w-100 justify-content-between">
                <h1 className="text-primary">
                  {texts[formLanguage].bigHeadingContract}
                </h1>
              </div>
            </div>
            <div className="col-auto">
              <select
                className="form-control"
                value={formLanguage}
                onChange={onSelectChange}
              >
                <option value="de">German</option>
                <option value="en">English</option>
              </select>
            </div>
          </div>
          <div className="row mb2">
            <div className="col-12 col-md-8">
              <p>{texts[formLanguage].radioChoiceHeadingContract}</p>
              <form onSubmit={handleSearchSubmit}>
                <input
                  className="form-control"
                  type="text"
                  value={searchId}
                  placeholder="Künstler-ID eingeben"
                  onChange={(e) => setSearchId(e.target.value)}
                  disabled={initialData}
                />
                {searchFormError && (
                  <p className="text-danger mt-1">{searchFormError}</p>
                )}
                {!initialData ? (
                  <button className="btn btn-primary mt-2" type="submit">
                    Start
                  </button>
                ) : (
                  <p className="mt-1 mb-5">
                    {texts[formLanguage].radioChoiceRestartWithOther}
                  </p>
                )}
              </form>
            </div>
          </div>
          {initialData && (
            <ContractForm
              initialData={initialData}
              formLanguage={formLanguage}
            />
          )}
        </PageSection>
      )}
    </Layout>
  )
}

export default ConfirmContract
